.logo {
  height: 40px !important;
  min-width: 31px;
  cursor: pointer;
}

:global .ant-row-rtl {
  :local .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
}

