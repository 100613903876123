@import url('../../../styles/variables.less');

.root {
  .leftSection {
    padding: 20px !important;
    border: 1px solid @border-color;
  }

  :global(.ant-form-item) {
    margin-bottom: 24px !important;
  }
}

