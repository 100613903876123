@import '~antd/lib/style/themes/variable.less';

.bulbIcon {
  color: @success-color;
}

.collapse :global(.ant-collapse-content-box) {
  padding-inline: 12px;
}

.firstFormItem {
  margin-top: 16px;
}

.panel {
  :global(.ant-collapse-header) {
    padding-inline: 0 !important;
  }

  :global(.ant-collapse-content-box) {
    padding-inline: 36px !important;
  }

  :global(.anticon) {
    color: @primary-color;
  }
}

:global(.ant-ribbon-wrapper) {
  :global(.ant-ribbon-placement-end) {
    top: -5px !important;
  }

  > label {
    min-height: 28px;
    text-align: center;
    flex: 1;
    box-sizing: content-box;
    padding-block: 5px;
    height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      line-height: 1.4;
    }
  }
}

