@import '../../styles/variables.less';

.footer {
  border-top: 1px solid @border-color;
}

.language {
  min-width: 100px;
}

