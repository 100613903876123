@import '~antd/lib/style/themes/variable.less';

.radioGroup {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;

  > label {
    min-height: 28px;
    text-align: center;
    flex: 1;
    box-sizing: content-box;
    padding-block: 5px;
    flex-grow: 1;
    width: 33%;
    height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      line-height: 1.4;
    }

    &:global(.ant-radio-button-wrapper-checked) {
      background: rgb(0 162 255 / 5%);
      color: @text-color;
      border-width: 1px;
    }

    &:first-child {
      @border: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999)));
      border-top-left-radius: @border;
      border-bottom-left-radius: @border;
    }

    &:last-child {
      @border: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999)));
      border-top-right-radius: @border;
      border-bottom-right-radius: @border;
    }
  }
}

