@import '~antd/lib/style/themes/variable.less';

.menuWrapper {
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1;
}

.logoContainer {
  float: left;
  margin: 16px 24px 16px 0 !important;
}

.logoCol :global(.ant-image) {
  vertical-align: middle;
}

.header {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  background: #fff !important;
  display: flex;
  flex-flow: row nowrap;
  direction: ltr;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5%;

  &::after {
    content: '';
    position: absolute;
    top: @layout-header-height;
    border-bottom: 1px solid @border-color-base;
    left: 0;
    width: 100%;
    // box-shadow: @box-shadow-base;
  }

  :global .ant-menu-root {
    width: 350px;
    border-bottom: none;
  }
}

@media screen and (max-width: @screen-xs) {
  .header {
    padding-inline: 12px !important;
  }

  .logoContainer {
    margin-left: 0 !important;
  }

  .rightSectionSpace {
    margin-right: -8px;
  }
}

.drawer {
  :global .ant-drawer-content-wrapper {
    width: 100%;
    max-width: 300px;

    ul {
      border-right: none;
    }
  }
}

.avatar {
  background-color: @success-color;
  cursor: pointer;
}

:global(.ant-menu) {
  font-size: 1rem;
}

.userPopover {
  border-radius: 6px;
}

