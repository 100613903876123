@import '../../../../styles/variables.less';

@shop-icon-color: black;
@store-text-color: blue;

// Modal Body
.modalWrapper {
  :global .ant-modal-body {
    background-color: @gray-background-color;
    text-align: left;
  }
}

/* Card */
.audienceCard {
  border-radius: 5px;
  .store {
    color: @store-text-color;
  }
}

// Checked circle icon
.checkedIcon {
  color: @green-color;
}
.shopIcon {
  color: @shop-icon-color;
}

.centerText {
  text-align: center;
  display: inline-block;
}

