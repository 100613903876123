@import url('../../../styles/variables.less');

.page {
  // padding: 32px 32px 0 32px;
  min-height: 100vh;

  .languageTranslate {
    text-align: right;
  }

  .form {
    min-height: 100vh;
  }

  .container {
    max-width: max-content;
    min-width: 360px;
    margin: auto;
    border-color: @border-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    :global(.ant-typography) {
      word-break: normal !important;
    }
  }

  .card {
    max-width: 600px;
  }

  .content {
    font-size: 1.125rem;
  }

  .buttonGroup {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    :global(.ant-btn-primary) {
      border-radius: 0px !important;
    }
  }

  .btnText {
    justify-content: center;

    :global(.ant-typography) {
      color: inherit;
    }
  }

  .orderList {
    margin-block-start: 0.7rem;
    margin-block-end: 0.7rem;
    padding-inline-start: calc(1rem + 1px);
  }
  // :global(.ant-form-item) {
  //   margin-bottom: 0 !important;
  // }
  :global(.ant-card) {
    padding: 10px 50px !important;
  }
}

.cardTitle {
  text-align: center;
}
.cardTitle img {
  border-radius: 50%;
}

@media (max-width: 768px) {
  .page {
    :global(.ant-card) {
      padding: 10px !important;
    }
  }
}

