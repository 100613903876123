.spinnerContainer {
  width: 100vw;
  height: 100vh;
}

.bottomPopover {
  padding: 26px;
  width: 340px;
  background: #fff;
  box-shadow: 0 0 24px 4px rgb(0 0 0 / 9%);
  border-radius: 12px;
  position: fixed;
  bottom: 0px;
  left: 32px;
  z-index: 99;
  transition: all 0.5s;
  line-height: 20px;
  text-align: center;
  & .actionBtn {
    margin-top: 12px;
  }
  & .closeIcon {
    text-align: right;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    & svg {
      height: 12px;
      width: 12px;
    }
  }
}

