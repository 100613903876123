@import '~antd/lib/style/themes/variable.less';
@import '../../../styles/variables.less';

.modal {
  top: 0;
  margin: 0;
  max-width: 100vw;
  padding: 0;
  height: 100vh;

  :global(.ant-card) {
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%) !important;
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
  }

  & > :global(.ant-modal-content) {
    background-color: @background-color-base;
    height: 100vh !important;
    display: flex;
    flex-flow: column nowrap;
  }

  & > :global(.ant-modal-content > .ant-modal-body) {
    padding-inline: 0;
    width: 100%;
    flex-grow: 1;
    height: unset;
    max-height: 100%;
    overflow-y: scroll;
    background-color: #f0f2f5 !important;
  }
  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  @media screen and (max-width: @screen-xs) {
    .container {
      &,
      :global(.ant-modal-header) {
        padding-inline: 12px;
      }
    }
  }

  & > :global(.ant-modal-content > .ant-modal-header) {
    border-bottom: 1px solid #d6d6d6;
    padding-inline: 6%;
    width: 100%;
    padding-block: 8.5px;

    :global(.ant-modal-title) {
      max-width: 1600px;
      margin-inline: auto;
    }
  }
}

.modalWrapper {
  overflow: hidden;
  max-width: 100vw;
}

.questionIcon,
.primaryText {
  color: @primary-color;
}

.container {
  padding-inline: 10%;
  // min-height: 100vh;
  margin: 0 auto;
  max-width: 1600px; // TODO: make global
}

.footerSpace > * {
  flex: 1;
}

.closeBtn {
  border-radius: 50%;
  background: rgb(153, 153, 153) !important;
  color: white !important;
  height: 44px !important;
  width: 44px !important;

  & > :global(.anticon) {
    font-size: 1.25rem;
  }
}

.collapse {
  padding-inline: 0 !important;

  :global(.ant-collapse-header) {
    //display: none !important;
    margin-bottom: 12px; // margin of advanced settings card "margin-top: 12px"
    align-items: center !important;
    padding-inline-start: 0 !important;
    padding-block: 0 !important;

    :global(.anticon) {
      color: @primary-color;
    }
  }

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }

  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

