.fixFontSize(@propName: padding-left, @fontSize: 8px) {
  @{propName}: calc(@font-size-base + @fontSize);
}

.ant-alert-description {
  .fixFontSize(line-height);
}

.ant-popover-message-title {
  .fixFontSize();
}

.ant-upload-list-item-name {
  .fixFontSize();
}

.ant-upload-list-item-progress {
  .fixFontSize(@fontSize: 12px);
}
