.timer {
  display: block;
  margin-bottom: 16px;
  width: 100%;
  //background-color: #e9f4fc;
  background-color: #f0f2f5;
  word-break: break-all;
  padding: 8px 16px;
}

