@import '../Dashboard/index.module.less';
@import '../../../styles/variables.less';

@gray-border-color: @border-color;

.header {
  align-items: baseline;
  border-bottom: 1px solid @gray-border-color;
  padding: 5px 0;
  margin-bottom: 2.4rem;

  a {
    text-decoration: underline;
  }
}

.leftArrowIcon {
  font-size: 1.2rem;
  color: black;
}

.campaignsTable :global(.ant-table-row .ant-table-cell:first-child) {
  padding-left: 0 !important;
}

.campaignsTable
  :global(.ant-table-container table > thead > tr:first-child th:first-child) {
  padding-left: 0 !important;
}

.campaignsTable :global(.ant-table-thead > tr > th) {
  font-size: 0.875rem;
}

