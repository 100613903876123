@import url('../../styles/variables.less');

@footer-bg-color: #f8f8f8;

.modal {
  width: 550px !important;

  :global {
    .ant-modal-body {
      overflow: auto;
      max-height: calc(100vh - 140px);
      height: 100%;
    }
  }

  :global .ant-modal-footer {
    background-color: @footer-bg-color;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse;
    gap: 10px;
  }
}

.cancelBtn {
  color: unset;

  a:hover {
    text-decoration: underline;
  }

  &,
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border: none !important;
    box-shadow: none !important;
  }
  //text-decoration: underline;
}

.container {
  .list {
    padding-inline: 24px !important;
    margin: 0 !important;
    color: #6c6c6c;
  }
  .card {
    background-color: @footer-bg-color;
    border: 1px solid @border-color;

    & > :global(.ant-typography) {
      font-size: 14px;
      display: block;
      padding-inline: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  :global {
    .ant-collapse {
      background-color: inherit;
      border: none;
    }
    .ant-collapse-item {
      border-bottom: none;
    }
    .ant-collapse-content {
      background-color: @footer-bg-color;
      border-top: none;
    }
    .ant-collapse-content-box {
      & > .ant-space > .ant-space-item:last-child {
        border-top: 1px dashed @border-color;
        padding-top: 16px;
      }
    }
  }
}

.validationModal {
  :global(.ant-result-title) {
    text-align: center;
    font-size: 15px;
  }
  :global .ant-modal-footer {
    background-color: @footer-bg-color;
    text-align: center;
  }
  :global {
    .ant-result-subtitle {
      text-align: center;
      & > .ant-typography {
        text-align: left;
      }
    }
  }
}

