@import '~antd/lib/style/themes/variable.less';

.inputGroup {
  :global(.ant-space-item) {
    width: 50%;
  }
}

.headings {
  display: block;
  margin-bottom: 8px;
  width: 100%;
  word-break: break-all;
}

.previewText {
  background-color: #00a2ff26;

  & > :global(.ant-image) {
    vertical-align: bottom;
  }
}

.space {
  padding: 10px;
  background-color: #fff;
  text-align: center;

  :global(.ant-space-item) {
    width: 100%;
  }

  @media screen and (min-width: @screen-xs) {
    position: sticky;
    top: 0;
  }
}

.rightSection {
  text-align: left;
  padding: 0 12px;

  .infoBox {
    display: flex;
    justify-content: space-between;
  }

  .declineBtn {
    float: right;
    font-size: 0.75rem;
  }

  .total {
    margin-bottom: 7px;
  }

  :global(.ant-form-item-label) {
    padding-bottom: 3px;
  }

  :global(.ant-space) {
    width: 100%;
  }
}

.payBtn {
  white-space: break-spaces;
  height: auto;
}

.contentContainer {
  :global(.ant-image) {
    margin: 10px 12px;
  }
}

.discountBracket {
  color: #0ca127;
  //font-size: 1.8rem
  //font-weight: 400
}

