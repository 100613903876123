@import '../../../styles/variables.less';

.root {
  :global(.ant-card) {
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
  }
}

.loadMoreBtn {
  text-align: center;
  padding: 12px 8px;
}

