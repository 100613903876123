@import '../../styles/variables.less';

.audienceDetail {
  background-color: @gray-background-color;
  padding: 10px;
  border-radius: 5px;
  & .entity {
    color: @primary-color;
  }
  & .operator {
    color: @green-color;
  }
}
.audienceDetail .ant-col:nth-child(2) {
  text-align: right;
}

.audienceDetail :global(.ant-btn-icon-only) {
  width: 32px;
  height: 32px;
  padding: 0 0;
}
.ruleText {
  :global(.ant-typography) {
    white-space: nowrap !important;
    word-break: normal !important;
  }
  & > :global(.ant-space-item):last-child {
    :global(.ant-typography) {
      white-space: normal !important;
    }
  }
}

