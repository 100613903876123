.modal {
  :global(.ant-modal-title) {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
  }

  :global(.ant-modal-body) {
    text-align: center;
    background-color: #fff;
  }

  :global(.ant-form-item-explain-error) {
    text-align: left;
  }
}

// Form
.form {
  padding: 20px 50px;
}

