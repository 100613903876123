@import '../../styles/variables.less';

@shop-icon-color: black;
@header-height: 55px;
@footer-height: 61px;
@searchbar-height: 56px;
@modal-body-height: 400px;

.font12 {
  font-size: 0.75rem;
}
.plusIcon {
  font-size: 1.5rem;
  vertical-align: middle;
  color: @primary-color;
}
.primaryText {
  color: @primary-color;
}

.createNewCard {
  cursor: pointer;
  .createNewTypo {
    line-height: 16px;
  }
}

.modal {
  // top: 0;
  // height: 100vh;
  // max-height: 100vh;

  .segmentsSelected {
    color: @primary-color;
  }
  .footer {
    width: 100%;
    justify-content: space-between;
    :global(.ant-form-item) {
      margin-bottom: 0 !important;
    }
  }
  :global .ant-modal-footer {
    height: @footer-height;
    padding: 10px 24px;
  }

  :global .ant-modal-header {
    height: @header-height;
  }
  :global .ant-modal-title {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
  }

  :global(.ant-modal-body) {
    padding: 0;
  }
}

// Modal Body
.modalWrapper {
  :global .ant-modal-body {
    background-color: @gray-background-color;
    text-align: left;
  }
}

/* Card */
.audienceCard {
  word-break: break-word;

  .audienceCardSpace {
    width: 100%;
  }

  .seeDetailBtn {
    color: @primary-color;
  }

  .selectBtn {
    padding: 4px 20px !important;
  }

  .default {
    // border-color: @green-color;
    // color: @green-color;
    &:focus {
      color: @green-color;
      border-color: @green-color;
    }
    &:hover {
      color: @green-color;
      border-color: @green-color;
    }
  }

  .selected {
    color: #fff;
    background-color: @green-color;

    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }
  :global(.ant-form-item) {
    margin: 0 !important;
  }
  :global .ant-card {
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
    border-color: @border-color;
  }
  :global .ant-card-body {
    padding: 12px 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

// Checked circle icon
.checkedIcon {
  color: @green-color;
}

// Tag Color
.tag {
  background-color: @gray-background-color;
}

.infiniteScrollRow {
  padding: 24px;
  width: 100%;
  // overflow: auto;
  // max-height: calc(@modal-body-height - (@searchbar-height));
  // max-height: calc(
  //   100vh - (@header-height + @footer-height + @searchbar-height)
  // );

  :global(.ant-card) {
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
    border-color: @border-color;
  }

  :global(.ant-card-body) {
    padding: 12px 24px;
  }
}

// Search field
.searchContainer {
  height: @searchbar-height !important;
}
.searchField {
  background-color: #fff;
  padding: 12px 24px;
  width: 100%;

  :global .ant-select-selector {
    border: none !important;
  }

  .searchInput {
    border-radius: 50px;
    background-color: @gray-background-color;

    input {
      background-color: @gray-background-color;
    }
  }
}

// Footer Btn
.footerBtn {
  width: 150px;
}

