@import '~antd/lib/style/themes/variable.less';
@import '../../../styles/variables.less';

// .collapse {
//   padding-inline: 0 !important;
//   :global(.ant-collapse-header) {
//     //display: none !important;
//     align-items: center !important;
//     padding-inline-start: 0 !important;
//     padding-block: 0 !important;

//     :global(.anticon) {
//       color: @primary-color;
//     }
//   }

//   :global(.ant-collapse-content-box) {
//     padding: 0 !important;
//   }

//   :global(.ant-form-item) {
//     margin-bottom: 0;
//   }
// }

.editBtn {
  .commonButtonStyles;
  background-color: #e4e6eb !important;
  border-color: #e4e6eb !important;
  color: @text-color !important;
}

// .collapseCard {
//   margin-top: 12px;
// }

.dateInput input {
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: -20%;
    position: absolute;
    right: 0;
    top: 0;
    width: 120%;
    display: inline-block;
  }
}

.timeFormItemLabelText {
  font-weight: 600;
  display: inline-block;
  margin-bottom: 8px;
}

.endDateInputSpace {
  margin-top: 16px;
}

.contentContainer {
  width: 100%;
  margin-top: 12px;
}

