@import '../../../../styles/variables.less';

@input-margin-top: 18px;

.primaryText {
  color: @primary-color !important;
}

.lastFormItem {
  margin-bottom: 0;
}

.percentText {
  font-size: 2rem;
  color: @primary-color;
  font-weight: 600;
}

.discountGroup {
  & > :global(.ant-space-item) {
    &:nth-child(3) {
      margin-top: @input-margin-top;
    }
    &:last-child {
      margin-top: @input-margin-top;
    }
  }
}

