.list {
  :global(.ant-list-split .ant-list-item) {
    border-bottom: 0;
  }

  :global(.ant-radio-group) {
    width: 100% !important;
  }
  :global(.ant-list-item-meta-title) {
    margin-bottom: 0 !important;
  }
}

.listItem {
  align-items: flex-start;
}

.avatar {
  width: 48px;
  height: 48px;
  background-color: #e4e6eb;
}

