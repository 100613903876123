.heading {
  color: blue;
}

.statusRibbonUi_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #e3e7eb;
  z-index: 1;

  // border: 1px solid

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;

  .openStatusModalBtn {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .content {
    display: flex;
    flex-flow: column;
    gap: 5px;

    > * {
      margin-block: 0 !important;
    }

    h2 {
      color: #ff0000;
    }
  }
}

