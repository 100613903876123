.scrollable {
  overflow: auto hidden;
  max-width: calc(100vw - 30px);
}

.container {
  position: relative;
}

// Scrollable div container effects

.leftSide,
.rightSide,
.centered {
  position: relative;
  &::after {
    box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 15%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  &::before {
    box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 15%);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
}
.leftSide {
  &::before {
    box-shadow: none;
  }
}
.rightSide {
  &::after {
    box-shadow: none;
  }
}

