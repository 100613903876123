@import '~antd/lib/style/themes/variable.less';
@import '../../../styles/variables.less';

.contentSpace {
  min-width: 630px;
  width: 100%;
  display: flex;
}

table {
  word-break: break-word;
}

.loadMoreBtn {
  text-align: center;
  padding: 12px 8px;
}

.infoIcon {
  color: @primary-color;
}

.colorGreen {
  color: @green-color;
}

.colorBlue {
  color: @primary-color;
}

.font13 {
  font-size: 0.8125rem;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.campaignName {
  cursor: pointer;
  font-size: 1rem;
  white-space: break-spaces;
  margin-bottom: 0 !important;
}

.campaignNameChangePriority {
  font-size: 1rem;
}

.campaignName:hover {
  color: @primary-color;
  text-decoration: underline;
}

// .moreActions div {
//   padding: 5px 0px;
//   border-bottom: 1px solid #f7f7f7;
// }

// .moreActions div:last-child {
//   border-bottom: none;
// }

.campaignsTable table {
  font-size: 0.875rem;
  // border-left: 1px solid @border-color;
  // border-right: 1px solid @border-color;
  line-height: 20px;
}

.campaignsTable :global(.ant-popover-inner-content) {
  padding-top: 0px;
  padding-bottom: 0px;
}

.campaignsTable .campaignDates:global(.ant-space) {
  gap: 0 !important;
}

.campaignDates {
  :global(.ant-typography) {
    word-break: normal !important;
  }
}

.campaignsTable
  :global(.ant-table.ant-table-middle .ant-table-tbody > tr > td) {
  // vertical-align: top;
  padding: 18px 8px;
}

// .campaignsTable :global(.ant-table-tbody > tr) {
//   cursor: pointer;
// }

.campaignsTable :global(.ant-table-thead > tr > th) {
  padding: 10px 8px !important;
  border-top: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
  line-height: 18px;
  font-weight: 400;
  font-size: 0.6875rem;
}

.campaignsTable :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: rgb(226 237 243 / 60%);
}

.campaignsTable :global(.ant-table-tbody > tr > td) {
  border-bottom: 1px solid @border-color;
}

.campaignsTable :global(.ant-table-row .ant-table-cell:first-child) {
  padding-left: 18px !important;
}

.campaignsTable
  :global(.ant-table-container table > thead > tr:first-child th:first-child) {
  padding-left: 18px !important;
}

.fontWeight400 {
  font-weight: 400;
}

.campaignsTable {
  & .live {
    color: @green-color;
    text-transform: capitalize;
  }
  & .paused {
    color: @red-color;
    text-transform: capitalize;
  }
  & .scheduled {
    color: @primary-color;
    text-transform: capitalize;
  }
}

.campaignsTable :global(.ant-typography.ant-typography-secondary) {
  color: rgba(0, 0, 0, 0.6);
}

.campaignsTable :global(.ant-table-footer) {
  background: #fff;
  text-align: center;
}

.campaignsTable :global(.ant-switch-checked) {
  background-color: @green-color;
}

.segmentsTooltip {
  padding-left: 8px;
  flex: 1;
  white-space: normal;
  margin-bottom: 0 !important;
}

.segementsName {
  display: flex;
  flex-wrap: nowrap;

  :global(.ant-space-item) {
    line-height: 18px;
  }
}

.dateText {
  padding-left: 8px;
}

.summarySection {
  border-top: 1px solid @border-color;
  background: radial-gradient(
      circle at 0px 0px,
      rgb(255, 255, 255) 0px,
      rgba(255, 255, 255, 0.91) 1%,
      rgba(226, 237, 243, 0) 24%,
      rgba(226, 237, 243, 0) 100%
    ),
    linear-gradient(
      120deg,
      rgb(226, 237, 243) 0px,
      rgb(255, 255, 255) 130%,
      rgb(255, 255, 255) 100%
    );
  & :global(.ant-col) {
    padding: 12px;
  }
  & .totalRevenue {
    font-size: 1.4rem;
    font-weight: 600;
    color: @primary-color;
  }
  & .firstColumn {
    padding-left: 35px;
  }
  & .lastColumn {
    padding-right: 35px;
    text-align: right;
  }
  & :global(.ant-btn) {
    padding: 0px;
  }
  & .rightAligned {
    text-align: right;
  }
}

.summaryDetails {
  width: 100%;

  :global(.ant-col) {
    padding: 0;
  }
}

.dateTimeTip {
  color: #fff;
}

@media screen and (max-width: @screen-sm) {
  .contentSpace {
    gap: 8px !important;
  }
}

.orderAnalysisButton {
  border-color: @green-color !important;
  background-color: rgb(4 206 164 / 10%);

 &:hover, &:active, &:focus {
   border-color: @green-color;
   color: @green-color;
 }

  &:focus, &:hover {
    background-color: #fff;
  }
}

// @media screen and (max-width: @screen-md) {
//   .contentSpace {
//     min-width: 600px;
//   }
// }

