@import '../../styles/variables.less';

.launchCampaignBar {
  max-width: 1600px;
  width: 100%;
  padding: 20px;
  padding-inline: 7%;
  margin: 0 auto;
  background: radial-gradient(
      circle at 0px 0px,
      rgb(255, 255, 255) 0px,
      rgba(255, 255, 255, 0.91) 9%,
      rgba(226, 237, 243, 0) 24%,
      rgba(226, 237, 243, 0) 100%
    ),
    linear-gradient(
      120deg,
      rgb(226, 237, 243) 0px,
      rgb(255, 255, 255) 100%,
      rgb(255, 255, 255) 100%
    );
  line-height: 22px;
  & .subDiv {
    padding: 0px 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: inherit;

    & > :global(.ant-space-item:nth-child(2)) {
      flex: 1;
    }
    & .heading {
      font-size: 22px;
      font-weight: 600;
      margin: 0px;
    }
  }
  & .extraButton {
    background: @green-color;
    border-color: @green-color;
  }
}

