.sider :global(.ant-menu-root) {
  border-right: none;
}

.sider :global(.ant-menu-item) {
  height: 30px;
}

.siderMenu {
  height: 100%;
}

.siderMenuTitle {
  cursor: default;
  user-select: none;
  padding-left: 0 !important;

  &:active {
    background: #fff !important;
  }
}

