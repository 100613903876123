@primary-color: #00a2ff;
@layout-body-background: #fff;
@green-color: #04cea4;
@red-color: red;
@line-height-base: 24px;
@gray-background-color: #f0f2f5;
@text-color-secondary: rgba(0, 0, 0, 0.6);
@slider-track-background-color: @primary-color;
@slider-handle-color-focus: @primary-color;
@slider-handle-color: @primary-color;
@slider-track-background-color-hover: @primary-color;
@border-color: #e4e5e6;

// font size overrides
@font-size-base: 1rem;
@font-size-lg: 1.125rem;
@font-size-sm: 0.875rem;

.commonButtonStyles() {
  height: auto;
  padding: 0.4375rem 0.9375rem;
  border-radius: 6px !important;
  font-weight: 600;
}
