@import url('../../../styles/variables.less');

.modal {
  .helpText {
    color: @primary-color;
  }
  .questionMark {
    vertical-align: middle !important;
  }

  :global(.ant-form-item) {
    margin-bottom: 0 !important;
  }
  :global {
    .ant-modal-body {
      height: 360px;
      // max-height: calc(100vh - 81px - 61px);
      // min-height: 360px;
      overflow: auto;
    }
  }
}

