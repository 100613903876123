.templateCard {
  border-radius: 10px;
  cursor: default;
}
.description {
  min-height: 44px;
  // margin: 0px !important;
}
//   Tags font color
.tags {
  color: black;
}

// Action Button
.button {
  padding: 0;
}

// Cover Image
.coverImage {
  text-align: center;
}

