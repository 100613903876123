.layout {
  min-height: 100vh;
}

.content {
  padding: 0 7%;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

