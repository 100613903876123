@import '~antd/lib/style/themes/variable.less';
@import '../../../../styles/variables.less';

.select {
  &,
  & :global(.ant-select-selector) {
    height: 100%;
  }

  :global(.ant-select-selector) {
    padding: 3px 6px;
  }
}
.tag {
  margin-right: 3px;
  white-space: break-spaces;
  word-break: break-all;
}
.addNewBtn {
  .commonButtonStyles;
  background-color: #e4e6eb !important;
  border-color: #e4e6eb !important;
  color: @text-color !important;
}

