@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import './styles/variables.less';
@import './styles/antd-fix.less';

// Font size based on screen resolutions
html,
body {
  font-size: 14px;
}
@media screen and (max-width: 1280px) {
  html,
  body {
    font-size: 14px;
  }
}

@media screen and (min-width: 1280px) {
  html,
  body {
    font-size: 16px;
  }
}
@media screen and (min-width: 1920px) {
  html,
  body {
    font-size: 18px;
  }
}

// Styles
body {
  overflow: auto !important;
}

// Primary button
.primaryButton {
  .commonButtonStyles;
}

// secondary button
.secondaryButton {
  border-color: @primary-color;
  color: @primary-color;
  .commonButtonStyles;
}

// font sizes
.font14 {
  font-size: 0.875rem;
}
.font16 {
  font-size: 1rem;
}

// line awesome icons
.las {
  font-size: 1.25rem;
  vertical-align: middle;
  margin-left: 5px;
}

.ant-modal-header {
  border-bottom: 1px solid @border-color;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed @primary-color !important;
}

tr.drop-over-upward td {
  border-top: 2px dashed @primary-color !important;
}

@media screen and (max-width: @screen-lg) {
  .mobileHidden {
    display: none !important;
  }
}

@screenlg-min: @screen-lg + 1;

@media screen and (min-width: @screenlg-min) {
  .pcHidden {
    display: none !important;
  }
}

.fullWidth {
  width: 100%;
}

.ant-input {
  border-radius: 4px !important;
}

// ant card
.ant-card-bordered {
  border: 1px solid @border-color;
}

// ant typography levels
h1.ant-typography {
  font-size: 2rem;
}
h2.ant-typography {
  font-size: 1.5rem;
}
h3.ant-typography {
  font-size: 1.2rem;
}
h4.ant-typography {
  font-size: 1rem;
}
h5.ant-typography {
  font-size: 0.83rem;
}
.ant-typography {
  word-break: normal;
  white-space: initial;
}

.ant-tag {
  white-space: break-spaces;
  word-break: break-all;
}
.ant-btn-icon-only > * {
  font-size: 1.4rem;
}
.ant-divider {
  border-top: 1px solid #d7d7d7 !important;
}

