@import '../../styles/variables.less';

@header-height: 55px;
@footer-height: 61px;
@radio-group-height: 46px;
@searchbar-height: 57px;
@modal-body-height: 505px;

.radioGroup {
  width: 100%;
  :global(.ant-radio-wrapper:first-child) {
    margin-right: 44px;
  }
}
.radioButton {
  text-align: center;
  border-bottom: 1px solid @border-color;
  padding: 8px 24px;
}
.searchField {
  padding: 12px 48px;
  border-bottom: 1px solid @border-color;
}
.products {
  padding-left: 24px;
}

.modal {
  .tableTree {
    :global(.ant-table-row-level-1 td:first-child) {
      padding-left: 50px;
    }
    :global(.ant-table-cell) {
      padding: 8px 16px;
    }

    :global(.ant-table-row) {
      border-bottom: 1px solid @border-color;
    }

    :global(.ant-table-body) {
      // max-height: calc(
      //   @modal-body-height - (@radio-group-height + @searchbar-height)
      // ) !important;
      overflow: hidden !important;
    }

    :global(.ant-checkbox) {
      z-index: 1200;
    }
  }

  :global(.ant-table-tbody > tr.ant-table-row-selected > td) {
    background: none !important;
  }

  :global(.ant-modal-body) {
    padding: 0;
    height: @modal-body-height;
    max-height: calc(100vh - 140px);
    overflow: auto;
  }
  :global(.ant-modal-title) {
    text-align: center;
    font-weight: 600;
  }
  :global(.ant-image) {
    vertical-align: middle !important;
  }
}

// search input
.searchInput {
  border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
  // border-top: 1px solid @border-color;
  // border-bottom: 1px solid @border-color;
  :global(.ant-input-group-addon) {
    background-color: #fff;
  }
  :global(.ant-input-affix-wrapper) {
    border-top-left-radius: max(
      0px,
      min(8px, calc((100vw - 4px - 100%) * 9999))
    ) !important;
    border-bottom-left-radius: max(
      0px,
      min(8px, calc((100vw - 4px - 100%) * 9999))
    ) !important;
  }
  :global(.ant-input-search-button) {
    border-top-right-radius: max(
      0px,
      min(8px, calc((100vw - 4px - 100%) * 9999))
    ) !important;
    border-bottom-right-radius: max(
      0px,
      min(8px, calc((100vw - 4px - 100%) * 9999))
    ) !important;
  }
}

.footerBtn {
  width: 150px;
}

