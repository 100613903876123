@import '../../styles/variables.less';
@import '~antd/lib/style/themes/variable.less';

// Modal
.modal {
  :global(.ant-modal-body) {
    padding: 0px;
    text-align: left;
  }
  :global(.ant-radio-group) {
    line-height: 16px;
  }
  :global(.ant-modal-title) {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
  }
}

// Radio Button
.radioButton {
  text-align: center;
  padding: 8px 24px;
  border-bottom: 1px solid @border-color;
}

// Template
.templateRoot {
  text-align: left;
  padding: 24px;
  background: @gray-background-color;

  .selectMenu {
    text-align: right;
  }
}

.mr5 {
  margin-right: 5px;
}

.width60 {
  width: 60% !important;
}

.width40 {
  width: 40% !important;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}

.cursorPointer {
  cursor: pointer;
}

.spinner {
  text-align: center;
  padding: 30px;
}

.displayTable {
  display: table;
  & .child {
    display: table-cell;
    vertical-align: middle;
  }
}

.segmentsCreationTabs {
  padding: 8px 24px;
  font-size: 0.875rem;
  line-height: 1.2;
  & :global(.ant-tabs-nav) {
    margin: 0px;
  }
  & :global(.ant-card-head-title) {
    padding: 16px 0px 0px 0px;
  }
  & :global(.ant-select),
  :global(.ant-picker-input > input),
  :global(.ant-input) {
    font-size: 0.875rem;
  }
  & .subcategoryTitle {
    font-size: 1rem;
  }
  & :global(.ant-card) {
    border-bottom: 1px solid @border-color;
  }
  & :global(.ant-card-extra) {
    padding: 10px 0px;
  }
  & :global(.ant-card-head) {
    padding-left: 0px;
    padding-right: 0px;
    min-height: auto;
    border-bottom: none;
  }
  & :global(.ant-tabs-tab) {
    font-weight: 600;
    padding: 12px 0px 4px 0px;
    margin-bottom: 3px;
  }
  & .leafFieldsRow {
    padding: 10px 0px;
    border-bottom: 1px solid #f1f1f1;
    align-items: center;
  }
  & :global(.ant-badge-count) {
    background: @primary-color;
    font-weight: 400;
  }
  & :global(.ant-badge-status) {
    vertical-align: super;
  }
  // & :global(.ant-picker) {
  //   height: 100%;
  // }
  & .tagbox {
    border: 1px solid #d9d9d9;
    padding: 4px 10px 6.5px 10px;
    border-radius: 4px;
    & .ant-input {
      width: 50%;
    }
  }
  & :global(.ant-col) {
    padding: 0px 5px;
  }
}

.editBtn {
  .commonButtonStyles;
  background-color: #e4e6eb !important;
  border-color: #e4e6eb !important;
  color: @text-color !important;
  padding: 3px 15px;
  margin-right: 15px;
}

.loadMoreBtn {
  text-align: center;
  padding: 12px 8px;
}

.templateHeading {
  padding: 8px 24px;
  border-bottom: 1px solid @border-color;
}

.templateHeading :global(.anticon) {
  font-size: 1rem;
  margin-right: 8px;
  vertical-align: middle;
  color: @primary-color;
}

