@import '~antd/lib/style/themes/variable.less';
@import '../../../../styles/variables.less';

.seeAllBtn {
  padding: 0 !important;
}

.editBtn {
  .commonButtonStyles;
  background-color: #e4e6eb !important;
  border-color: #e4e6eb !important;
  color: @text-color !important;
}

