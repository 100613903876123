@import '~antd/lib/style/themes/variable.less';

.layout {
  min-height: 100vh;
}

.mainContentContainerContent {
  padding: 0 7%;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.contentLayout {
  padding: 24px 0;

  :global(aside) {
    background: none;
  }
}

.mainContent {
  padding-left: 70px;
  min-height: 280px;
}

@media screen and (max-width: @screen-lg) {
  .mainContentContainerContent {
    padding-inline: 12px !important;
  }

  .mainContent {
    padding-inline: 0;
  }
}

